<template>
  <div class="board">
    <div v-if="isDesktop()" style="width:100%;">
      <div class="board__row" v-for="(row,i) in randomedCards" :key="i">
        <div v-if="isGameEnded" style="display:none;"></div>
        <Card
          v-for="(card, j) in row"
          :key="card.id"
          :flipped="card.flipped"
          :number="card.number"
          :done="card.done"
          :image="card.image"
          :dark="computeDarkDesktop(i,j)"
          @click.native="onCardClick(card)"
          @animationend.native="verifySelectedCards()"
        />
      </div>
    </div>
    <div v-if="!isDesktop()" class="board__row">
      <Card
        v-for="(card, i) in randomedCards"
        :key="card.id"
        :flipped="card.flipped"
        :number="card.number"
        :done="card.done"
        :image="card.image"
        :dark="i == 1 || i == 3 || i == 4 || i == 6 || i == 9 || i == 11 || i == 12"
        @click.native="onCardClick(card)"
      />
    </div>
  </div>
</template>

<script>
import { shuffle } from "lodash";
import uuid from "uuid/v1";

import Card from "./Card";

export default {
  name: "Board",

  components: {
    Card
  },

  created() {
    const cardPairsCount = 7;
    for (let i = 0; i < cardPairsCount; i++) {
      for (let j = 0; j < 2; j++) {
        let image = document
          .querySelector(`#game-card-image-${i}`)
          .getAttribute("data-src");

        this.cards.push({
          id: uuid(),
          number: i,
          flipped: false,
          done: false,
          image
        });
      }
    }
  },

  data() {
    return {
      cards: [],
      selectedCards: [],
      cardsPicked: false,
      modalNotVisible: true,
      cardsCountInRow: [3, 3, 4, 4],
      cardsCountInRowMobile: [3, 3, 3, 4],
      images: [],
      gameEnded: false
    };
  },

  watch: {
    isGameEnded(newValue) {
      if (newValue) {
        this.win();
      }
    }
  },

  computed: {
    randomedCards() {
      if (this.isDesktop()) {
        return this.divideOnRows([3, 3, 4, 4]).map(row => {
          return shuffle(row);
        });
      } else {
        return shuffle(this.cards);
      }
    },

    isGameEnded() {
      return this.cards.every(card => card.done);
    }
  },

  methods: {
    onCardClick(card) {
      if (this.cardsPicked) {
        return false;
      }

      if (card.done) {
        return;
      }

      if (this.selectedCards.length) {
        if (card.id === this.selectedCards[0].id) {
          return;
        }
      }

      const pickedCard = this.cards[this.cards.indexOf(card)];
      pickedCard.flipped = true;

      this.selectedCards.push(pickedCard);

      if (this.selectedCards.length >= 2) {
        this.cardsPicked = true;
        setTimeout(() => {
          this.verifySelectedCards();
          this.cardsPicked = false;
        }, 1000);
      }
    },

    verifySelectedCards() {
      if (this.selectedCards[0].number === this.selectedCards[1].number) {
        this.selectedCards.forEach(card => {
          card.done = true;
        });
        this.selectedCards = [];
      } else {
        this.selectedCards.forEach(card => {
          card.flipped = false;
        });
        this.selectedCards = [];
      }
    },

    onModalClose() {
      this.modalNotVisible = false;
    },

    divideOnRows(oneRowCardsCountArray) {
      let startCursor = 0;
      let endCursor = 0;
      let divided = [];

      for (let i = 0; i < oneRowCardsCountArray.length; i++) {
        let currentRowCardsCount = oneRowCardsCountArray[i];
        endCursor += currentRowCardsCount;
        divided.push(this.cards.slice(startCursor, endCursor));
        startCursor += currentRowCardsCount;
      }

      return divided;
    },

    isDesktop() {
      return window.innerWidth > 700 ? true : false;
    },

    computeDarkDesktop(i, j) {
      if (i == 0 && j % 2 !== 0) {
        return true;
      } else if (i == 1 && j % 2 == 0) {
        return true;
      } else if (i == 2 && j % 2 == 0) {
        return true;
      } else if (i == 3 && j % 2 !== 0) {
        return true;
      } else {
        return false;
      }
    },

    win() {
      this.gameEnded = true;
      global.console.log(document.querySelector("#game-pop-up"));
      document.querySelector("#game-pop-up").style.display = "flex";
    }
  }
};
</script>

<style lang="scss" scoped>
.board {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-end;

  @media screen and (max-width: 700px) {
    width: 100%;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &__row {
    display: flex;
    justify-content: flex-end;

    @media screen and (max-width: 700px) {
      width: 100%;
      justify-content: flex-start;
      flex-wrap: wrap;
    }
  }
}
</style>
