<template>
  <div id="app">
    <Board />
  </div>
</template>

<script>
import Board from './components/Board';

export default {
  name: 'App',
  components: {
    Board
  }
};
</script>

<style lang="scss">
html,
body {
  margin: 0;
  font-family: 'Arial', sans-serif;
}
</style>
