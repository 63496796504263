<template>
  <div class="flip-card" :class="{ flipped: flipped, done: done }">
    <div class="flip-card-inner">
      <div class="flip-card-front" :class="{ 'flip-card-front_dark': dark }"></div>
      <div class="flip-card-back">
        <img :src="image" alt />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    number: Number,
    flipped: Boolean,
    done: Boolean,
    dark: Boolean,
    image: String
  }
};
</script>

<style lang="scss" scoped>
.flip-card {
  background-color: transparent;
  height: 130px;
  width: 130px;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s;

  @media screen and (max-width: 700px) {
    width: 25%;
    height: 100px;
  }
}

.done {
  cursor: default;
  opacity: 0.5;
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flipped .flip-card-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
  background-color: #ebebeb;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;

  &:before {
    content: "";
    background: url("http://test2.webdress.site/wp-content/uploads/2020/02/logo_game.png");
    background-size: contain;
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    padding: 0 15px;
    box-sizing: border-box;
  }
}

.flip-card-front_dark {
  background: #d9d9d9;
}

/* Style the back side */
.flip-card-back {
  //background-color: dodgerblue;
  background: transparent;
  color: white;
  transform: rotateY(180deg);
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
